





























































import Vue from 'vue'
import { mapGetters } from 'vuex'
import Account, { AccountType } from '../../../../store/models/Account'
import Organization from '../../../../store/models/Organization'

export default Vue.extend({
  data() {
    return {
      columns: [
        { label: 'SFD', field: 'name', searchable: true },
        { label: 'Décaissement MTN', field: 'mtnBalance' },
        { label: 'Remboursement MTN', field: 'mtnRefundBalance' },
        { label: 'Interêts MTN', field: 'mtnInterestBalance' },
        { label: 'Décaissement MOOV', field: 'moovBalance' },
        { label: 'Remboursement MOOV', field: 'moovRefundBalance' },
        { label: 'Interêts MOOV', field: 'moovInterestBalance' }
      ]
    }
  },

  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    items(): Array<Organization> {
      return Organization.query()
        .where('type', 'sfd')
        .whereIdIn(this.getCurrentProduct.sfdConcern)
        .with('balances')
        .all()
    }
  },

  methods: {
    getAccounts(organizationId: string | null): Array<Account> {
      if (organizationId)
        return Account.query()
          .where((account: Account) => {
            return (
              account.owner == organizationId &&
              account.productId == this.getCurrentProduct.id
            )
          })
          .get()
      else
        return Account.query()
          .where((account: Account) => {
            return (
              this.getCurrentProduct.sfdConcern.includes(account.owner) &&
              account.productId == this.getCurrentProduct.id
            )
          })
          .get()
    },
    soldeDecaissementMoov(organizationId: string | null): number {
      const accounts = this.getAccounts(organizationId)
      const moovAccount = accounts.find((value: any) => {
        return value.type == AccountType.DISBURSEMENT && value.otm == 'moov'
      })
      if (moovAccount) return moovAccount.balance
      return 0
    },

    soldeDecaissementMtn(organizationId: string | null): number {
      const accounts = this.getAccounts(organizationId)
      const mtnAccount = accounts.find((value: any) => {
        return value.type == AccountType.DISBURSEMENT && value.otm == 'mtn'
      })
      if (mtnAccount) return mtnAccount.balance
      return 0
    },

    soldeDecaissement(organizationId: string | null): number {
      return (
        this.soldeDecaissementMtn(organizationId) +
        this.soldeDecaissementMoov(organizationId)
      )
    },

    soldeRembourssementMoov(organizationId: string | null): number {
      const accounts = this.getAccounts(organizationId)
      const moovAccount = accounts.find((value: any) => {
        return value.type == AccountType.REFUND && value.otm == 'moov'
      })
      if (moovAccount) return moovAccount.balance
      return 0
    },

    soldeRembourssementMtn(organizationId: string | null): number {
      const accounts = this.getAccounts(organizationId)
      const mtnAccount = accounts.find((value: any) => {
        return value.type == AccountType.REFUND && value.otm == 'mtn'
      })

      if (mtnAccount) return mtnAccount.balance
      return 0
    },

    soldeRembourssement(organizationId: string | null): number {
      return (
        this.soldeRembourssementMoov(organizationId) +
        this.soldeRembourssementMtn(organizationId)
      )
    },
    soldeInteretMoov(organizationId: string | null): number {
      const accounts = this.getAccounts(organizationId)
      const moovAccount = accounts.find((value: any) => {
        return value.type == AccountType.INTEREST && value.otm == 'moov'
      })
      if (moovAccount) return moovAccount.balance
      return 0
    },

    soldeInteretMtn(organizationId: string | null): number {
      const accounts = this.getAccounts(organizationId)
      const mtnAccount = accounts.find((value: any) => {
        return value.type == AccountType.INTEREST && value.otm == 'mtn'
      })

      if (mtnAccount) return mtnAccount.balance
      return 0
    },

    soldeInteret(organizationId: string | null): number {
      return (
        this.soldeInteretMoov(organizationId) +
        this.soldeInteretMtn(organizationId)
      )
    }
  },

  created() {
    Organization.api().fetch({ includes: ['balances'] })
    Account.api().fetchAll()
  }
})
