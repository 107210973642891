var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-box',{attrs:{"container":"","p":"6"}},[_c('v-data-table',{attrs:{"paginated":"","hide-add-action":"","title":"Solde des OVAs","columns":_vm.columns,"items":_vm.items},scopedSlots:_vm._u([{key:"mtnBalance",fn:function(ref){
var sfd = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm.soldeDecaissementMtn(sfd.id)))+" ")]}},{key:"mtnRefundBalance",fn:function(ref){
var sfd = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm.soldeRembourssementMtn(sfd.id)))+" ")]}},{key:"mtnInterestBalance",fn:function(ref){
var sfd = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm.soldeInteretMtn(sfd.id)))+" ")]}},{key:"moovBalance",fn:function(ref){
var sfd = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm.soldeDecaissementMoov(sfd.id)))+" ")]}},{key:"moovRefundBalance",fn:function(ref){
var sfd = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm.soldeRembourssementMoov(sfd.id)))+" ")]}},{key:"moovInterestBalance",fn:function(ref){
var sfd = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm.soldeInteretMoov(sfd.id)))+" ")]}},{key:"table-footer",fn:function(){return [_c('tr',[_c('td',{staticClass:"px-6 py-3"},[_vm._v("Totaux")]),_c('td',{staticClass:"px-6 py-3"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.soldeDecaissementMtn()))+" ")]),_c('td',{staticClass:"px-6 py-3"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.soldeRembourssementMtn()))+" ")]),_c('td',{staticClass:"px-6 py-3"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.soldeInteretMtn()))+" ")]),_c('td',{staticClass:"px-6 py-3"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.soldeDecaissementMoov()))+" ")]),_c('td',{staticClass:"px-6 py-3"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.soldeRembourssementMoov()))+" ")]),_c('td',{staticClass:"px-6 py-3"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.soldeInteretMoov()))+" ")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }