import { render, staticRenderFns } from "./balances.vue?vue&type=template&id=487a2fc0&"
import script from "./balances.vue?vue&type=script&lang=ts&"
export * from "./balances.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports